
import { reactive } from "vue";

export const collections = reactive({
    products:[
        {id:"1",pname:"Inspiron 15 Laptop",make:"DELL",pcat:"ENTERPRISE",partno:"icc-c783511win8",hsn:"84713010",cgst:"18",sgst:"18",igst:"18",mrp:"79989.99",desc:"",price:"75500",package:""},
        {id:"2",pname:"Inspiron 14 Laptop 8GB",make:"DELL",pcat:"ENTERPRISE",partno:"icc-c782512win8",hsn:"84713010",cgst:"18",sgst:"18",igst:"18",mrp:"72489.98",desc:"",price:"71000",package:""},
        {id:"3",pname:"Inspiron 14 Laptop 16GB",make:"DELL",pcat:"LAPTOP",partno:"icc-c782519win8",hsn:"84713010",cgst:"18",sgst:"18",igst:"18",mrp:"66989.99",desc:"",price:"62400",package:""},
        {id:"4",pname:"Inspiron 15 3000 laptop",make:"DELL",pcat:"LAPTOP",partno:"d560509win9s",hsn:"84713010",cgst:"3",sgst:"3",igst:"3",mrp:"46489.98",desc:"",price:"44800",package:""},
        {id:"5",pname:"HP 34W78PA",make:"HP",pcat:"ACC",partno:"15s-fr2006TU",hsn:"84713010",cgst:"5",sgst:"5",igst:"5",mrp:"46499",desc:"11th Generation Intel® Core™ i3 processor",price:"45500",package:""},
        {id:"6",pname:"HP LaserJet Pro M126nw",make:"HP",pcat:"ACC",partno:"235991",hsn:"84713746",cgst:"14",sgst:"14",igst:"14",mrp:"21329.00",desc:"HP LaserJet Pro M126nw Wireless Black & White Multi-Function Laserjet Printer (Wireless Direct Printing, CZ175A, Black)",price:"20850",package:""},  
    ],
    categories:[
        {cat:"APPLE",name:"Apple"},
        {cat:"ENTERPRISE",name:"Enterprise"},
        {cat:"ACC",name:"Accessory"},
        {cat:"SOFTWARE",name:"Softwares"}
    ]
});

export const getProductTemplate=function(){
    let template = {id:"",pname:"Inspiron 15 3000 laptop",make:"DELL",pcat:"LAPTOP",partno:"d560509win9s",hsn:"84713010",cgst:"3",sgst:"3",igst:"3",mrp:"46489.98",desc:"",price:"44800",package:""};
    return template;
}

