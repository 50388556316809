<template>
  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  -->
  <div class="container-fluid">
    <div class="row">
        <div class="col-sm-auto bg-light sticky-top">
            <div class="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
                <a href="#" class="d-block p-3 link-dark text-decoration-none" title="" data-bs-toggle="tooltip" 
                    data-bs-placement="right" data-bs-original-title="Icon-only">
                    <img v-bind:src="require('@/assets/logo1.png')" width="50" height="50"/>
                </a>
                <ul class="nav nav-pills nav-flush flex-sm-column mb-auto mx-auto flex-row flex-nowrap  text-start
                           justify-content-between w-100 px-3 ">
                    <li class="nav-item">
                        <a href="/" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
                            <i class="bi bi-house-fill"></i> Home&nbsp;&nbsp;&nbsp;
                        </a>
                    </li>
                    
                    <li class="nav-item">
                        <a href="/prospectmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Customers">
                            <i class="bi bi-person-bounding-box"></i>  Prospect&nbsp;&nbsp;
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/quotationmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Customers">
                            <i class="bi bi-quote"></i>  Quotation&nbsp;&nbsp;
                        </a>
                    </li>
                    
                    <li class="nav-item">
                        <a href="/invmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Customers">
                            <i class="bi bi-cart"></i>  Invoice&nbsp;&nbsp;
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/prodmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Products">
                            <i class="bi bi-box"></i>  Products&nbsp;
                        </a>
                    </li>
                    <!--
                    <li class="nav-item">
                        <a href="/vendmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                            <i class="bi bi-card-list"></i> Vendors&nbsp;&nbsp;
                        </a>
                    </li>
                    -->
                    <li class="nav-item">
                        <a href="/custmgmt" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Orders">
                            <i class="bi bi-person-workspace"></i> Customers
                        </a>
                    </li>
                    
                    
                </ul>
            </div>
        </div>
        <div class="col-sm p-3 min-vh-100">
            <!-- content -->
            <router-view/>
        </div>
    </div>
</div>
  
</template>

<script>
import {collections} from '@/services/productService.js';

export default {
    provide:{
        collections
    }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
