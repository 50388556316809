import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/custmgmt',
    name: 'CustMgmt',
    component: () => import(/* webpackChunkName: "cust" */ '../views/CustomerMgmt.vue')
  },
  {
    path: '/prodmgmt',
    name: 'ProdMgmt',
    component: () => import(/* webpackChunkName: "prod" */ '../views/ProductMgmt.vue')
  },
  {
    path: '/vendmgmt',
    name: 'VendMgmt',
    component: () => import(/* webpackChunkName: "vend" */ '../views/VendorMgmt.vue')
  },
  {
    path: '/invmgmt',
    name: 'InvMgmt',
    component: () => import(/* webpackChunkName: "inv" */ '../views/InvoiceMgmt.vue')
  },
  {
    path: '/prospectmgmt',
    name: 'ProspectMgmt',
    component: () => import(/* webpackChunkName: "inv" */ '../views/ProspectMgmt.vue')
  },
  {
    path: '/quotationmgmt',
    name: 'QuotationMgmt',
    component: () => import(/* webpackChunkName: "inv" */ '../views/QuotationMgmt.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
